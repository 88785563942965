import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input() getMenuStatus: boolean;
  @Output() getMenusStatusChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  closeMenu() {
    this.getMenusStatusChange.emit(false)
  }

}
