import { Component, OnInit } from '@angular/core';
import { PatternsMapsService } from '../patterns.service';
import * as d3 from 'd3';
import { event as currentEvent } from 'd3-selection';
import GeoTIFF from 'geotiff';

@Component({
  selector: 'app-patterns',
  templateUrl: './patterns.component.html',
  styleUrls: ['./patterns.component.scss']
})
export class PatternsComponent implements OnInit {

  filterValues;
  words2 = new Array();
  columns = new Array();
  svg;
  mapLayer;
  projection;
  gMap;
  markerPosition;
  markerLayer;

  constructor(private staticMapService: PatternsMapsService) {
    this.staticMapService.getValues()
      .subscribe((filterValues: Array<any>) => {
        for (let filter of filterValues) {
          this.columns.push(filter.name)
          this.words2.push("notSet");
        }
        this.filterValues = filterValues;
      });
  }

  ngOnInit() {
    //this.createBaseMap();
    this.canvasMap();
  }

  canvasMap() {
    this.svg = d3.select('svg');
    let w = this.svg.style("width").replace("px", "");
    let h = this.svg.style("height").replace("px", "");
    console.log(w)
    console.log(h)
    this.projection = d3.geoMercator()
      .scale(1)
      .center([0, 0])
      .translate([0, 0]);
    var path = d3.geoPath()
      .projection(this.projection);

    this.gMap = this.svg.append('g');
    this.mapLayer = this.svg.append('g');
    this.markerLayer = this.svg.append('g');

    d3.json("/assets/geojson/world.json")
      .then((json: any) => {
        const b = path.bounds(json);

        const s = 0.99 / Math.max((b[1][0] - b[0][0]) / w, (b[1][1] - b[0][1]) / h);

        // transform
        const t = [(w - s * (b[1][0] + b[0][0])) / 2, (h - s * (b[1][1] + b[0][1])) / 2];

        this.projection
          .scale(s)
        .translate(t);
        this.markerLayer.selectAll("path")
          .data(json.features)
          .enter()
          .append("path")
          .attr("d", path)
          .style("stroke", "#d9d9dc")
          .style("fill", "transparent"); //#d9d9dc


        const raster_w = 8234 / 10
        const raster_h = 6102 / 10
        this.mapLayer.append("image")
          .attr('id', 'europe')
          .attr("xlink:href", "https://patterns.stopextinctions.org/api/v1/geo-patterns?columns=class&filters=Amphibia")
          .attr("width", raster_w)
          .attr("height", raster_h);
      });

    var zoom = d3.zoom()
      .scaleExtent([1, 8])
      .on('zoom', () => {
        this.markerLayer.selectAll('path')
          .attr('transform', currentEvent.transform);
        
      });

    this.svg.call(zoom);

  }

  createBaseMap() {
    this.svg = d3.select('svg');
    this.projection = d3.geoMercator()
      .scale(100)
      .center([0, 0])
      .translate([500, 400]);
    var path = d3.geoPath()
      .projection(this.projection);

    this.gMap = this.svg.append('g');
    this.mapLayer = this.svg.append('g');
    this.markerLayer = this.svg.append('g');

    d3.json("/assets/geojson/world.json")
      .then((json: any) => {
        this.gMap.selectAll("path")
          .data(json.features)
          .enter()
          .append("path")
          .attr("d", path)
          .style("stroke", "rgb(43, 1, 53)")
          .style("fill", "rgb(43, 1, 53)"); //#d9d9dc
      });
    this.staticMapService.getGeoJson("class", "Amphibia")
      .subscribe(geoJson => {
        this.drawMap(geoJson)
      });

    var context = this;
    this.mapLayer.on("mousedown.log", function (ard,) {
      context.addMarker(d3.mouse(this))
    });
  }

  addMarker(mousePosition) {
    this.markerPosition = this.projection.invert(mousePosition);
    this.markerLayer.selectAll("rect").remove();
    this.markerLayer.append("rect")
      .attr("x", mousePosition[0] - 5)
      .attr("y", mousePosition[1] - 5)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", "red");
  }

  drawMap(geoJson) {
    var path = d3.geoPath()
      .projection(this.projection);
    this.mapLayer.selectAll("path").remove();
    var features = geoJson.features;

    let colors = [...new Set(geoJson.features.map(item => item.properties.stroke))];
    let levels = [...new Set(geoJson.features.map(item => item.properties["level-value"]))];

    this.mapLayer.selectAll('path')
      .data(features)
      .enter().append('path')
      .attr('d', path)
      .attr("fill", (d: any) => d.properties.stroke);

    var zoom = d3.zoom()
      .scaleExtent([1, 8])
      .on('zoom', () => {
        this.gMap.selectAll('path')
          .attr('transform', currentEvent.transform);
        this.mapLayer.selectAll('path')
          .attr('transform', currentEvent.transform);
        this.markerLayer.selectAll('rect').remove();
      });

    this.svg.call(zoom);

    this.svg.transition()
      .duration(750)
      .call(zoom.transform, d3.zoomIdentity);

    //.style('fill', fillFn)
    //.on('mouseover', mouseover)
    //.on('mouseout', mouseout)
    //.on('click', clicked);

  }

  drawNewMap() {
    let search_columns = new Array();
    let search_values = new Array();
    for (let i = 0; i < this.words2.length; i++) {
      if (this.words2[i] !== "notSet") {
        search_columns.push(this.columns[i])
        search_values.push(this.words2[i])
      }
    }
    if (search_columns.length > 0) {
      this.staticMapService.getGeoJson(search_columns, search_values)
        .subscribe(geoJson => {
          this.drawMap(geoJson)
        })
    }
  }

}
