import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PatternsMapsService {

  API_URL: string = "https://lsxf779i6g.execute-api.us-east-1.amazonaws.com/beta/api";

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  public getStaticMaps(): Observable<any> {
    let observable = new Observable<any>(observer => {
      let staticMaps = this.localStorageService.get("static-maps");
      if (staticMaps) {
        observer.next(staticMaps);
      } else {
        this.http.get(`${this.API_URL}/static-layers`).subscribe(staticMaps => {
          this.localStorageService.set("static-maps", staticMaps);
          observer.next(staticMaps);
        });
      }
    });
    return observable;
  }

  public getGeoJson(columns, values) {
    return this.http.get(`https://patterns.stopextinctions.org/api/v1/patterns?columns=${columns}&filters=${values}`)
  }

  public getValues() {
    return this.http.get(`https://patterns.stopextinctions.org/api/v1/values`)
  }

  public getOtherStaticMaps(): Observable<any> {
    let observable = new Observable<any>(observer => {
      let staticMaps = this.localStorageService.get("other-static-maps");
      if (staticMaps) {
        observer.next(staticMaps);
      } else {
        this.http.get(`${this.API_URL}/maps`).subscribe(staticMaps => {
          this.localStorageService.set("other-static-maps", staticMaps);
          observer.next(staticMaps);
        });
      }
    });
    return observable;
  }
}
