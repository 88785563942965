import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisualizeService {

  API_URL: string = "https://lsxf779i6g.execute-api.us-east-1.amazonaws.com/beta/api";

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  public getClassesSummary() {
    let body = {
      "field": "class"
    }
    return this.http.post(`${this.API_URL}/summary`, body);
  }

  public getFields(): Observable<any> {
    let observable = new Observable<any>(observer => {
      let summaryFields = this.localStorageService.get("summary-fields");
      if (summaryFields) {
        observer.next(summaryFields);
      } else {
        this.http.get(`${this.API_URL}/summary`).subscribe(summaryFields => {
          this.localStorageService.set("summary-fields", summaryFields);
          observer.next(summaryFields);
        });
      }
    });
    return observable;
  }

  public getValues(field) {
    let observable = new Observable<any>(observer => {
      let fieldValues = this.localStorageService.get(`summary-fields-${field}`);
      if (fieldValues) {
        observer.next(fieldValues);
      } else {
        let body = {
          "field": field
        }
        this.http.post(`${this.API_URL}/summary?size=1500`, body).subscribe(fieldValues => {
          this.localStorageService.set(`summary-fields-${field}`, fieldValues);
          observer.next(fieldValues);
        });
      }
    });
    return observable;
  }

  public getFilteredData(field, filter, value, size) {
    let filter_body = {};
    filter_body[filter] = value;
    let body = {
      "field": field,
      "search_type": "match",
      "fields": filter_body
    }
    console.log(body)
    let url = `${this.API_URL}/summary?&size=${size}`;
    return this.http.post(url, body);
  }

  public getColorData(size) {
    return this.http.get(`${this.API_URL}/colors?n_colors=${size}&seed=34`);
  }

}
