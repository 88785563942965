import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  keyframes,
  AnimationTriggerMetadata,
} from '@angular/animations';

/*export const fader: AnimationTriggerMetadata =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter, :leave', [
        style({
          opacity: 0
        }),
      ]),
      query(':enter', [
        animate('600ms linear', style({ opacity: 1 })),
      ])
    ]),
  ]);*/

const optional = { optional: true };

let toTheRight = [
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: '0',
      right: 0,
      width: '100%',
    })
  ], optional),
  query(':enter', [
    style({ right: '-100%',  })
  ]),
  group([
    query(':leave', [
      animate('300ms ease', style({ right: '100%', }))
    ], optional),
    query(':enter', [
      animate('300ms ease', style({ right: '0%'}))
    ])
  ]),
];

let toTheLeft = [
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: '0',
      left: 0,
      width: '100%',
    })
  ], optional),
  query(':enter', [
    style({ right: '-100%',  })
  ]),
  group([
    query(':leave', [
      animate('300ms ease', style({ left: '100%', }))
    ], optional),
    query(':enter', [
      animate('300ms ease', style({ left: '0%'}))
    ])
  ]),
]

export const Animations = {
  sliderTransition: trigger('routeAnimations', [
    transition('isLastLeft => isLeft', toTheRight),
    transition('isLastRight => isLeft', toTheLeft),
    transition('isLeft => isLastLeft', toTheLeft),
    transition('isLastLeft => *', toTheRight),
    transition('isLastRight => *', toTheLeft),
    transition('* => isLeft', toTheLeft),
    transition('* => isLastLeft', toTheLeft),
    transition('* => isLastRight', toTheRight),
    transition('* => isRight', toTheRight),
    transition('isRight => *', toTheLeft),
    transition('isLeft => *', toTheRight)
  ])
};
