import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  isMenuOpen = false;

  constructor(private router: Router) {
    if (localStorage['firstTimeLoad'] != 'TRUE') {
      localStorage['firstTimeLoad'] = 'TRUE';
      router.navigate(["first-time-welcome"]);
    }
  }

  ngOnInit() {
  }

  showMenu() {
    this.isMenuOpen = true;
  }

  closeMenu(event) {
    this.isMenuOpen = false;
  }

}
