import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/search.service';
import { VisualizeService } from 'src/app/visualize.service';
import { MapService } from '../map.service';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-species',
  templateUrl: './species.component.html',
  styleUrls: ['./species.component.scss', '../explore.component.scss']
})
export class SpeciesComponent implements OnInit {

  fields;
  filter;
  values;
  value;
  species;
  specie;
  wikipedia;
  scientificName;
  sameSpeciesRecords;
  showStatusByCountry = false;
  legends;
  specieLegends;

  constructor(private visualizeService: VisualizeService, private searchService: SearchService, private mapService: MapService, private route: ActivatedRoute, private router: Router) {
    this.visualizeService.getFields()
      .subscribe(fields => {
        let fieldsArray = Object.keys(fields).map(i => {
          return { "columnName": i, "displayName": fields[i]};
        })
        this.fields = fieldsArray;
      })
    route.queryParams.subscribe(p => {
      if (p.scientificName) {
        this.openCard(p.scientificName);
      }
    });
    this.searchService.getLegends()
      .subscribe(legedns => this.legends = legedns);
  }

  ngOnInit() {
  }

  addFilter(filter) {
    this.filter = filter;
    this.visualizeService.getValues(filter)
      .subscribe(({ content }: any) => {
        this.values = content.filter(value => value);
      })
  }

  addValue(value) {
    this.value = value;
    let filter = `${this.filter}=${this.value}`;
    this.searchService.searchSpecie(filter)
      .subscribe(species => {
        this.species = species;
      });
  }

  setScientificName(scientificName) {
    const queryParams: Params = { scientificName: scientificName };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

  openCard(scientificName) {
    this.scientificName = scientificName;
    this.searchService.getSpecieByScientificName(this.scientificName)
      .subscribe(({ results: species, layer_name: layerData } : any) => {
        this.specie = species[0];
        this.sameSpeciesRecords = species;
        let uniqueLegends = species.map(specie => specie.legend).filter((item, index, array) => array.indexOf(item) === index);
        this.specieLegends = []
        for (let legend of uniqueLegends) {
          this.specieLegends.push({"legend": legend, "color": this.legends[legend]});
        }
        this.mapService.setLegend(this.specieLegends);
        let layer = this.createLayer(layerData);
        this.mapService.addLayer(layer);
          
      })
    this.searchService.getWikipediaData(this.scientificName)
      .subscribe(wikipediaData => {
        this.wikipedia = wikipediaData;
      })
  }

  clearFilters() {
    this.filter = null;
    this.values = null;
  }

  clearValues() {
    this.value = null;
    this.species = null;
  }

  clearSpecie() {
    const queryParams: Params = { scientificName: null };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    this.mapService.removeLayerByName(this.specie.scientific_name);
    this.wikipedia = null;
    this.specie = null;
    this.scientificName = null;
  }

  createLayer(specie) {
    let format = 'image/png';

    let layer = new TileLayer({
      visible: true,
      name: specie.scientific_name,
      queryable: true,
      id: specie.scientific_name,
      source: new TileWMS({
        url: specie.layer_url,
        server: 'geoserver',
        params: {
          'FORMAT': format,
          'VERSION': '1.1.1',
          tiled: true,
          STYLES: '',
          LAYERS: specie.layer_name
        }
      })
    });
    return layer;
  }

  onShowStatusByCountry() {
    this.showStatusByCountry = !this.showStatusByCountry;
  }

}
