import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  API_URL: string = "https://lsxf779i6g.execute-api.us-east-1.amazonaws.com/beta/api";

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  public getFields(): Observable<any> {
    let observable = new Observable<any>(observer => {
      let filterFields = this.localStorageService.get("filter-fields");
      if (filterFields) {
        observer.next(filterFields);
      } else {
        this.http.get(`${this.API_URL}/search`).subscribe(filterFields => {
          this.localStorageService.set("filter-fields", filterFields);
          observer.next(filterFields);
        });
      }
    });
    return observable;
  }

  public getSpecieByScientificName(scientificName) {
    return this.http.get(`${this.API_URL}/species?scientific-name=${scientificName}`);
  }

  public searchSpecie(filters) {
    let body = {
      "search_type": "match",
      "fields": filters
    };
    console.log(body)
    return this.http.post(`${this.API_URL}/search`, body);
  }

  public search(keyword) {
    let body = {
      "search_type": "multy-match",
      "fields": {
        "common_na": keyword,
        "scientific": keyword,
        "order_": keyword,
        "class": keyword
      }
    };
    return this.http.post(`${this.API_URL}/search`, body);
  }

  public getWikipediaData(keyword) {
    return this.http.get(`${this.API_URL}/wikipedia?scientific-name=${keyword}`);
  }

  public getLegends() {
    let observable = new Observable<any>(observer => {
      let filterFields = this.localStorageService.get("legends");
      if (filterFields) {
        observer.next(filterFields);
      } else {
        this.http.get(`${this.API_URL}/legends`).subscribe(filterFields => {
          this.localStorageService.set("legends", filterFields);
          observer.next(filterFields);
        });
      }
    });
    return observable;
  }
}
