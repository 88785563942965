import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { WelcomeScreenComponent } from './welcome-screen/welcome-screen.component';
import { VisualizeComponent } from './visualize/visualize.component';
import { ExploreComponent } from './explore/explore.component';
import { SearchComponent } from './explore/search/search.component';
import { PatternsComponent } from './explore/patterns/patterns.component';
import { PatternsComponent as PatternPage } from './patterns/patterns.component';
import { LayersComponent } from './explore/layers/layers.component';
import { SpeciesComponent } from './explore/species/species.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';


const routes: Routes = [
  {
    path: "home",
    component: MainLayoutComponent,
    data: { animation: 'isRight' },
    children: [
      {
        path: "explore",
        component: ExploreComponent,
        outlet: "workarea",
        children: [
          {
            path: "settings",
            component: LayersComponent,
            outlet: "overlayer",
            pathMatch: "full",
            data: { animation: 'isLastRight' }
          },
          {
            path: "search",
            component: SearchComponent,
            outlet: "overlayer",
            data: { animation: 'isRight' }
          },
          {
            path: "specie",
            component: SpeciesComponent,
            outlet: "overlayer",
            pathMatch: "full",
            data: { animation: 'isLeft' }
          },
          {
            path: "patterns",
            component: PatternsComponent,
            outlet: "overlayer",
            pathMatch: "full",
            data: { animation: 'isLastLeft' }
          },
          {
            path: "",
            component: SpeciesComponent,
            outlet: "overlayer",
            pathMatch: "full",
            data: { animation: 'isLastLeft' }
          }
        ]
      },
      {
        path: "visualize",
        component: VisualizeComponent,
        outlet: "workarea"
      },
      {
        path: "patterns",
        component: PatternPage,
        outlet: "workarea"
      }
    ]
  },
  {
    path: "",
    component: WelcomeScreenComponent,
    pathMatch: "full"
  },
  { path: '404', component: NotFoundPageComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
