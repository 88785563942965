import { Component, Input, OnInit } from '@angular/core';
import { PatternsMapsService } from 'src/app/patterns.service';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { MapService } from '../map.service';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source';

@Component({
  selector: 'app-patterns',
  templateUrl: './patterns.component.html',
  styleUrls: ['./patterns.component.scss', '../explore.component.scss']
})
export class PatternsComponent implements OnInit {

  staticMaps;
  actualStaticLayer;
  otherStaticMaps;
  @Input()
  map;

  constructor(private staticMapService: PatternsMapsService, private mapService: MapService) {
    this.staticMapService.getStaticMaps()
      .subscribe(staticMaps => this.staticMaps = staticMaps);
    this.staticMapService.getOtherStaticMaps()
      .subscribe(otherStaticMaps => this.otherStaticMaps = otherStaticMaps);
  }

  addGeoJSONLayer(geojsonObject) {
    console.log(geojsonObject)
    console.log(geojsonObject.features) 
    let vectorLayer = new VectorLayer({
      visible: true,
      name: "Pattern layer",
      queryable: true,
      id: "Pattern layer",
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject),
      })
    });
    console.log("fdsfdjkfdjlkjkl")
    console.log(vectorLayer)
    this.mapService.addLayer(vectorLayer);
  }

  ngOnInit() {
  }

  addStaticLayer(staticLayer) {
    this.actualStaticLayer = staticLayer;
    let layer = this.createStaticLayer(staticLayer);
    this.mapService.addLayer(layer);
  }

  clearStaticLayer() {
    this.mapService.removeLayerByName(this.actualStaticLayer.name);    
    this.actualStaticLayer = null;
  }

  createStaticLayer(staticLayer) {
    let format = 'image/png';

    let layer = new TileLayer({
      visible: true,
      name: staticLayer.name,
      queryable: true,
      id: staticLayer.name,
      source: new TileWMS({
        url: staticLayer.url,
        server: 'geoserver',
        params: {
          'FORMAT': format,
          'VERSION': '1.1.1',
          tiled: true,
          STYLES: '',
          LAYERS: staticLayer.layerName
        }
      })
    });
    return layer;
  }

}
