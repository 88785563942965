import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeScreenComponent implements OnInit {

  imageSectionGridData = [
    {
      title: "Explore the distribution patterns",
      footer: "Explore the patterns",
      imageSrc: "https://pve-static-content.s3.amazonaws.com/IMG_3038_mod.JPG",
      text: "Find how the groups of species distribute around the globe"
    },
    {
      title: "Read about the endangered species",
      footer: "Explore the species",
      imageSrc: "https://pve-static-content.s3.amazonaws.com/Jaguar+-+Ceballos_mod.jpg",
      text: "Find information about the dangered species"
    }
  ];

  partnershipsLogosData = [
    {
      src: "assets/CEF_logo_BlueTransparent-2.png",
      html: `
      <div class="partnership-logo-wrapper">
        <img class="partnership-logo" src="assets/CEF_logo_BlueTransparent-2.png"/>
      </div>
      <p></p>
      `,
      backgroundColor: "#fff",
      color: "#000"
    },
    {
      src: "assets/global_conservation.png",
      html: `
      <div class="partnership-logo-wrapper">
        <img class="partnership-logo" src="assets/global_conservation.png"/>
      </div>
      <p></p>
      `,
      backgroundColor: "#fff",
      color: "#000"
    },
    {
      src: "assets/full_logo_white.svg",
      html: `
      <div class="partnership-logo-wrapper">
                    <img class="partnership-logo" src="assets/full_logo_white.svg"/>
      </div>
      <p></p>
      `,
      backgroundColor: "#222",
      color: "#fff"
    },
    {
      src: "assets/birdlife.png",
      html: `
      <div class="partnership-logo-wrapper">
        <img class="partnership-logo" src="assets/birdlife.png"/>
      </div>
      <p></p>
      `,
      backgroundColor: "#fff",
      color: "#000"
    },
    {
      src: "assets/reptile.jpeg",
      html: `
      <div class="partnership-logo-wrapper">
        <img class="partnership-logo" src="assets/reptile.jpeg"/>
      </div>
      <p>The Reptile Database</p>
      `,
      backgroundColor: "#000",
      color: "#fff"
    },
    {
      src: "assets/IUCN_logo.svg",
      html: `
      <div class="partnership-logo-wrapper">
        <img class="partnership-logo" src="assets/IUCN_logo.svg"/>
      </div>
      <p></p>
      `,
      backgroundColor: "#fff",
      color: "#000"
    }
  ];

  constructor(private router: Router) { }

  ngOnInit() {
  }

  closeScreen() {
    this.router.navigate(["home", { outlets: { workarea: "explore" } }]);
  }

  scrollDown() {
    document.getElementById("image-grid").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    })
  }

}
