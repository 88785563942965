import { Component, OnInit } from '@angular/core';
import { VisualizeService } from '../visualize.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-visualize',
  templateUrl: './visualize.component.html',
  styleUrls: ['./visualize.component.scss']
})
export class VisualizeComponent implements OnInit {

  classes;
  totalSpecies;
  fields;
  values;
  filter;
  field;
  value;
  limit;
  data;

  public lineChartColors: Color[];
  lineChartOptions: ChartOptions = {
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: true,
          labelString: '# of Registries'
        }
      }]
    },
    responsive: true,
    maintainAspectRatio: false
  };

  ImpressionsChartLabels;
  ImpressionsChartData: ChartDataSets[];

  constructor(private summaryService: VisualizeService) {
    this.limit = 30;
    this.summaryService.getClassesSummary()
      .subscribe((content : any) => {
        this.classes = content.results;
        this.totalSpecies = content.results.reduce((a, b) => a + b.count, 0);
      });
    this.summaryService.getFields()
      .subscribe(raw_fields => {
        let fields = [];
        for (let key of Object.keys(raw_fields)) {
          fields.push({"columnName": key, "displayName": raw_fields[key]});
        }
        this.fields = fields;
        this.field = fields[0].columnName;
        this.filter = fields[fields.length - 1].columnName;
        this.updateFilterValues();
      })
  }

  updateFilterValues() {
    this.summaryService.getValues(this.filter)
      .subscribe((content : any) => {
        this.values = content.results.filter(value => value);
        this.value = this.values.filter(value => value.className)[0].className;
        this.drawChart();
      });
  }

  drawChart() {
    console.log(this.filter)
    console.log(this.value)
    this.summaryService.getFilteredData(this.field, this.filter, this.value, this.limit)
      .subscribe((data: any) => {
        data = data.results.filter(d => d);
        this.summaryService.getColorData(data.length)
          .subscribe(({colors}: any) => {
            this.ImpressionsChartLabels = data.map(d => d.className);
            this.ImpressionsChartData = [
              { data: data.map(d => d.count), backgroundColor: colors, label: `${this.field} per ${this.filter} with value ${this.value}` }
            ];
          })
      })
  }

  ngOnInit() {
  }

}
