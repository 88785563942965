import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private map;
  private legend;
  private legendsCallback;

  mapBaseLayersName = ["Aerial with labels", "Aerial", "Road", "Basic"]

  constructor() { }

  public initMap(map) {
    this.map = map;
  }

  public addLayer(layer) {
    let isOnMap = false;
    this.map.getLayers().forEach(l => {
      if (l && l.get('name') === layer.get("name")) {
        isOnMap = true;
      }
    });
    if (!isOnMap) {
      layer.setOpacity(0.7);
      this.map.addLayer(layer);
    }
  }

  public removeLayerByName(name) {
    this.map.getLayers().forEach(layer => {
      if (layer && layer.get('name') === name) {
        this.map.removeLayer(layer);
      }
    });
  }

  public changeMapStyle(layerName) {
    this.map.getLayers().forEach(layer => {
      if (layer && layer.get('name') === layerName) {
        layer.setVisible(true);
      } else if (this.mapBaseLayersName.filter(l => l === layer.get('name')).length > 0) {
        layer.setVisible(false);
      }
    });
  }

  public getLayersNames() {
    let layers = Array();
    this.map.getLayers().forEach(layer => {
      let layerName = layer.get('name');
      if (this.mapBaseLayersName.filter(l => l === layerName).length == 0) {
        layers.push(layerName);
      }
    });
    return layers;
  }

  public moveLayerUp(layerName) {
    this.map.getLayers().forEach(layer => {
      if (layer.get('name') === layerName) {
        console.log(layer.getZIndex())
        //this.map.raiseLayer(layers, 1);
      }
    });
  }

  public registerLegendHandler(callback) {
    this.legendsCallback = callback;
  }

  public setLegend(legend) {
    this.legend = legend;
    this.legendsCallback(legend);
  }

}
