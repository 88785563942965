import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MapService } from '../map.service';

@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.scss', '../explore.component.scss']
})
export class LayersComponent implements OnInit {

  activeLayers;

  constructor(private mapService: MapService, private route: ActivatedRoute, private router: Router) { 
    this.activeLayers = this.mapService.getLayersNames();
  }

  ngOnInit() {
  }

  changeMapStyle(layerName) {
    this.mapService.changeMapStyle(layerName);
  }

  removeLayer(layerName) {
    this.mapService.removeLayerByName(layerName);
    this.activeLayers = this.mapService.getLayersNames();
    this.mapService.setLegend(null);
    this.setScientificName(null);
  }

  moveLayerUp(layerName) {
    this.mapService.moveLayerUp(layerName);
  }

  setScientificName(scientificName) {
    const queryParams: Params = { scientificName: scientificName };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

}
