import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../search.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss', '../explore.component.scss']
})
export class SearchComponent implements OnInit {

  fields;
  keyword;
  species;
  isAdvancedSearch;
  filters;
  showAvailableFilters;
  availableFilters;
  activeFilters = [];
  fielterSelected;
  previousSearch;

  constructor(private filterService: SearchService, private router: Router, private route: ActivatedRoute) {
    route.queryParams.subscribe(p => {
      if (p.keyword) {
        this.keyword = p.keyword;
        this.searchSpecie();
      }
      if (p.searchType == "advanced") {
        this.isAdvancedSearch = true;
      }
    });
    this.filterService.getFields()
      .subscribe(fields => {
        let fieldsArray = Object.keys(fields).map(i => {
          return { "columnName": i, "displayName": fields[i]};
        })
        this.filters = fieldsArray
      });
  }

  removeFilter(filter) {
    this.activeFilters = this.activeFilters.filter(f => f.columnName !== filter.columnName);
  }

  advancedSearch() {
    const queryParams: Params = { searchType: "advanced" };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

  ngOnInit() {
  }

  showFilter() {
    this.updateAvailableFilters();
    this.showAvailableFilters = true;
  }

  updateAvailableFilters() {
    let tempFilters = [];
    for (let filter of this.filters) {
      let isOnActiveFilter = this.activeFilters.filter(f => f.displayName === filter.displayName).length > 0;
      if (!isOnActiveFilter) {
        tempFilters.push(filter);
      }
    }
    this.availableFilters = tempFilters;
  }

  updateFilterValue(filter, value) {
    filter.value = value;
    this.activeFilters = this.activeFilters.filter(f => f.columnName !== filter.columnName);
    this.activeFilters.push(filter);
  }

  makeAdvancedSearch() {
    let filters = {};
    for (let filterIndex = 0; filterIndex < this.activeFilters.length; filterIndex++) {
      filters[this.activeFilters[filterIndex].columnName] = this.activeFilters[filterIndex].value;
    }
    this.filterService.searchSpecie(filters)
      .subscribe((response: any) => {
        this.species = response;
      });
  }

  addFilter() {
    let filterSelected = this.filters.filter(f => f.columnName === this.fielterSelected)[0];
    filterSelected.value = "";
    this.showAvailableFilters = false;
    this.activeFilters.push(filterSelected);
    this.updateAvailableFilters();
  }

  searchSpecie() {
    this.previousSearch = this.filterService.search(this.keyword)
      .subscribe((response: any) => {
        this.species = response;
      });
  }

  openCard(scientificName) {
    const queryParams: Params = { scientificName: scientificName };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

}
