import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { WelcomeScreenComponent } from './welcome-screen/welcome-screen.component';
import { VisualizeComponent } from './visualize/visualize.component';
import { ExploreComponent } from './explore/explore.component';
import { SearchComponent } from './explore/search/search.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SideMenuComponent } from './side-menu/side-menu.component';

import * as Hammer from 'hammerjs';
import { PatternsComponent } from './explore/patterns/patterns.component';
import { SpeciesComponent } from './explore/species/species.component';
import { LayersComponent } from './explore/layers/layers.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { PatternsComponent as PatternPage } from './patterns/patterns.component';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    WelcomeScreenComponent,
    VisualizeComponent,
    ExploreComponent,
    SearchComponent,
    SideMenuComponent,
    PatternsComponent,
    PatternPage,
    SpeciesComponent,
    LayersComponent,
    NotFoundPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    ThemeService,
    {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
