import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterOutlet } from '@angular/router';
import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import * as olControl from 'ol/control';
import { MapService } from './map.service';
import { SearchService } from '../search.service';
import { Animations } from './route-animations';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
  animations: [
    Animations.sliderTransition
  ]
})
export class ExploreComponent implements OnInit {

  isClosed = true;
  isMiddleOpen = false;
  isFullOpen = false;
  map;
  searching = false;
  fields;
  keyword;
  scientificName;
  mapLegend;

  mapBaseLayersName = ["Aerial with labels", "Aerial", "Road", "Basic"]

  constructor(private mapService: MapService, private router: Router, private filterService: SearchService, private route: ActivatedRoute) { 
    this.filterService.getFields()
      .subscribe(fields => {
        let fieldsArray = Object.keys(fields).map(i => {
          return { "columnName": i, "displayName": fields[i]};
        })
        this.fields = fieldsArray;
      });
      route.queryParams.subscribe(p => {
        if(p.scientificName || p.keyword) {
          this.halfOpenOverLyaer();
        }
        this.keyword = p.keyword;
        this.scientificName = p.scientificName;
        this.openSpecie();
        if(p.searchType == "advanced") {
          this.openOverLyaer();
        }
      });
    this.mapService.registerLegendHandler(legend => this.setLegend(legend));
  }

  ngOnInit() {
    let base_layer1 = new TileLayer({
      name: this.mapBaseLayersName[0],
      visible: true,
      preload: Infinity,
      queryable: false,
      source: new BingMaps({
        key: 'AiXpl_iJ9vka91ugxW5OybC2RWcH9RJvBjbQDzh9TgZryPAP9-YdO6ig1Bj-qV85',
        imagerySet: 'AerialWithLabels'
      })
    });

    let base_layer2 = new TileLayer({
      name: this.mapBaseLayersName[1],
      visible: false,
      preload: Infinity,
      queryable: false,
      source: new BingMaps({
        key: 'AiXpl_iJ9vka91ugxW5OybC2RWcH9RJvBjbQDzh9TgZryPAP9-YdO6ig1Bj-qV85',
        imagerySet: 'Aerial'
      })
    });

    let base_layer3 = new TileLayer({
      name: this.mapBaseLayersName[2],
      visible: false,
      preload: Infinity,
      queryable: false,
      source: new BingMaps({
        key: 'AiXpl_iJ9vka91ugxW5OybC2RWcH9RJvBjbQDzh9TgZryPAP9-YdO6ig1Bj-qV85',
        imagerySet: 'Road'
      })
    });

    let base_layer4 = new TileLayer({
      name: this.mapBaseLayersName[3],
      visible: false,
      queryable: false,
      source: new OSM()
    });

    let coord_ini = olProj.fromLonLat([-116.58, 31.89]);

    this.map = new Map({
      target: 'map',
      layers: [
        base_layer1, base_layer2, base_layer3, base_layer4
      ],
      view: new View({
        projection: 'EPSG:3857',
        center: coord_ini,
        zoom: 3,
        minZoom: 2.8,
        maxZoom: 28,
      }),
      controls: olControl.defaults({
        attribution: false,
        zoom: true,
        rotate: false
      })
    });

    this.mapService.initMap(this.map);
  }

  onSwipeUp() {
    if (this.isClosed) {
      this.isClosed = false;
      this.isMiddleOpen = true;
    }
    else if (this.isMiddleOpen) {
      this.isFullOpen = true;
      this.isMiddleOpen = false;
    }
  }

  onSwipeDown() {
    if (this.isFullOpen) {
      this.isFullOpen = false;
      this.isMiddleOpen = true;
    }
    else if (this.isMiddleOpen) {
      this.isClosed = true;
      this.isMiddleOpen = false;
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  openOverLyaer() {
    this.isFullOpen = true;
    this.isMiddleOpen = false;
    this.isClosed = false;
  }

  halfOpenOverLyaer() {
    this.isFullOpen = false;
    this.isMiddleOpen = true;
    this.isClosed = false;
  }

  search() {
    this.halfOpenOverLyaer();
    const queryParams: Params = { keyword: this.keyword };
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: queryParams, 
        queryParamsHandling: 'merge', 
      });
  }

  keyupSearch() {
    if (this.keyword.length >= 3) {
      this.search();
    }
  }

  advancedSearch() {
    if (this.searching) {
      this.openOverLyaer();
      const queryParams: Params = { searchType: "advanced" };
      this.router.navigate(
        [], 
        {
          relativeTo: this.route,
          queryParams: queryParams, 
          queryParamsHandling: 'merge', 
        });
    }
  }

  startSearching() {
    this.searching = true;
  }

  closeSearch() {
    this.searching = false;
  }

  openSpecie() {
    if (this.scientificName) {
      this.router.navigate([{outlets: { workarea: "explore" }}],
      {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge', 
      });
    }
  }

  setLegend(legend) {
    this.mapLegend = legend;
  }

}
